.fancy span {
  display: inline-block;
}

.fancy>.outer {
  transition: transform 350ms ease;
}

.fancy:hover>.outer {
  transition-duration: 800ms;
}

.fancy:hover>.outer>.inner {
  animation: float 5s ease infinite;
}

.fancy:hover>.outer:nth-child(1) {
  transform: translate(-80%, 60%) rotate(8deg);
}

.fancy:hover>.outer:nth-child(2) {
  transform: translate(-40%, 20%) rotate(4deg);
}

.fancy:hover>.outer:nth-child(3) {
  transform: translate(-10%, 60%) rotate(-6deg);
}

.fancy:hover>.outer:nth-child(4) {
  transform: translate(0%, 8%) rotate(-8deg);
}

.fancy:hover>.outer:nth-child(5) {
  transform: translate(0%, -20%) rotate(5deg);
}

.fancy:hover>.outer:nth-child(6) {
  transform: translate(0%, 20%) rotate(-3deg);
}

.fancy:hover>.outer:nth-child(7) {
  transform: translate(0%, -40%) rotate(-5deg);
}




.fancy>.outer2 {
  transition: transform 350ms ease;
}

.fancy:hover>.outer2 {
  transition-duration: 800ms;
}

.fancy:hover>.outer2>.inner {
  animation: float 5s ease infinite;
}

.fancy:hover>.outer2:nth-child(1) {
  transform: translate(-80%, -23%) rotate(19deg);
}

.fancy:hover>.outer2:nth-child(2) {
  transform: translate(-40%, 20%) rotate(4deg);
}

.fancy:hover>.outer2:nth-child(3) {
  transform: translate(-23%, -55%) rotate(-10deg);
}

.fancy:hover>.outer2:nth-child(4) {
  transform: translate(0%, 8%) rotate(-8deg);
}

.fancy:hover>.outer2:nth-child(5) {
  transform: translate(0%, -20%) rotate(5deg);
}

.fancy:hover>.outer2:nth-child(6) {
  transform: translate(0%, 20%) rotate(-3deg);
}

.fancy:hover>.outer2:nth-child(7) {
  transform: translate(0%, -40%) rotate(-5deg);
}