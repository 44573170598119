.line-container {
    margin-top: 25px;
    display: flex;
    width: 100%;

    .progress-line {
        position: relative;
        margin: 0 auto;
        border-radius: 10px;
        width: 90%;
        background: grey;
        height: 5px;
        display: flex;
        justify-content: space-between;

        .progress {
            position: absolute;
            width: 75%;
            height: 100%;
            background: orange;
            transition: width 1s ease-in-out;
        }
    }

    .status {
        position: relative;
        top: -5.5px;

        .dot {
            width: 15px;
            height: 15px;
            border: 5px solid grey;
            // cursor: pointer;

            &.current {
                // background: white;
                border: 5px solid #3EE546;;
                background:#3EE546;;
            }

            &.completed {
                position: relative;
                background: orange;
                border: 5px solid orange;

                &::after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 3px);
                    left: calc(50% - 5.5px);
                    height: 5px;
                    width: 10px;
                    border-left: 2px solid white;
                    border-bottom: 2px solid white;
                    transform-origin: center;
                    transform: rotate(-45deg);
                    z-index: 2;
                }
            }

        }

    }

}