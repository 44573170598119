pre {
    --selector: var(--pink);
    --property: var(--teal);
    --punctuation: var(--white);
    --undefined: var(--white);
  
    // font-size: 3rem;
    // font-weight: bold;
    // color: var(--undefined);
    // background: hsl(222, 45%, 7%);
    // padding: 2rem;
    // border-radius: 1rem;
  
    position: relative;
  
    transform-style: preserve-3d;
    transform: perspective(5000px) rotateY(var(--rotateX)) rotateX(var(--rotateY));
    transition-timing-function: linear !important;
    transition: 0.1s !important;
  }

  pre::before,
  pre::after {
    content: "";
    position: absolute;
    border-radius: inherit;
  }
  
  /* shadow */
  // pre::before {
  //   inset: 0.75rem;
  //   border-radius: inherit;
  //   background: black;
  //   z-index: -1;
  //   transform: translateZ(-50px);
  //   filter: blur(15px);
  //   opacity: 0.5;
  // }
  
  /* gradient thingy */
  // pre::after {
  //   z-index: -2;
  //   inset: -1rem;
  //   transform: translateZ(-50px);
  // }
  
  // .selector {
  //   color: var(--selector);
  // }
  
  // .property {
  //   color: var(--property);
  // }
  
  // .punctuation {
  //   color: var(--punctuation);
  // }
  
  // .property + .punctuation {
  //   color: var(--property);
  // }
  
  .pre-container {
    position: relative;
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  }
  
  