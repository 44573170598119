.timeline {
    position: relative;
    max-width: 1200px;
    // margin: 100px auto;
    padding-bottom: 100px;
    // z-index: 10;
}

.timelineContainer {
    padding: 10px 50px;
    position: relative;
    width: 50%;
    animation: movedown 1s linear forwards;
    opacity: 0;
    z-index: 10;
}

@keyframes movedown {
    0% {
        opacity: 1;
        transform: translateY(-30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.timelineContainer:nth-child(1){
    animation-delay: 0s;
}
.timelineContainer:nth-child(2){
    animation-delay: 1.5s;
}
.timelineContainer:nth-child(3){
    animation-delay: 2.5s;
}
.timelineContainer:nth-child(4){
    animation-delay: 4s;
}

.text-box {
    padding: 20px 30px;
    background: white;
    position: relative;
    border-radius: 6px;
    font-size: 15px;
}

.left-container {
    left: 0;
}

.right-container {
    left: 50%;
}

.timelineContainer img {
    position: absolute;
    width: 40px;
    border-radius: 50%;
    right: -20px;
    top: 32px;
    z-index: 10;
}

.right-container img {
    left: -20px;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    background: #3EE546;
    top: 0;
    left: 50%;
    margin-left: -3px;
    z-index: 5;
    animation: moveline 6s linear forwards;
}

@keyframes moveline {
    0% {
        height: 0;
    }

    100% {
        height: 100%;
    }
}

.text-box h2 {
    font-weight: 600;
}

.text-box small {
    display: inline-block;
    margin-bottom: 15px;
}

.left-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid white;
    right: 6.3%;
}

.right-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid white;
    left: 6.3%;
}


@media screen and (max-width:600px) {
    .timeline{
        margin: 50px auto;
    }
    .timeline::after{
        left: 31px;
    }

    .timelineContainer{
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }

    .text-box{
        font-size: 13px;
    }

    .text-box small{
        margin-bottom: 10px;
    }

    .right-container{
        left: 0;
    }

    .left-container img, .right-container img{
        left: 10px;
    }

    .left-container-arrow, .right-container-arrow{
        border-right: 15px solid white;
        border-left: 0;
        left: 65px;
    }
}