// for sticky footer
// .footer {
//     position: fixed;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     background-color: red;
//     color: white;
//     text-align: center;
//  }

 .footer {
    width: 100%;
   //  height: 200px;
    background: #101010;
    color: white;
    text-align: center;
    padding: 50px 0px 50px 0px !important;
 }

 .contactContainer{
   display: flex;
   justify-content: center;
   gap: 16px
 }

 .socialMediaContainer{
   display:flex;
   width: 38px; 
   height: 38px;
   background: #3D3D3D;
   padding: 8px;
   border-radius: 25px;
   cursor: pointer;
 }


 @media (max-width: 425px) {
  .onlineText{
    font-size: 9px;
  }
}