.text-with-circle {
    position: relative;
    display: inline-block;
    text-wrap: nowrap;
  }
  
  .circle {
    width: 8px;
    height: 8px;
    background-color: #B0B0B0;; /* Change to your desired circle color */
    display: inline-block;
    position: absolute;
    right: -13px;
    top: 50%;
    transform: translateY(-50%);
  }

  .titleExperience{
    color: #000;
    font-family: Roboto Mono;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }

  .experienceDetails{
    color: #434343;
    font-family: Roboto Mono;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    text-transform: uppercase;
    display: flex;
  }

  .pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }