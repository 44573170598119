@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@media (min-width: 900px) {
  .mx-w-50 {
    max-width: 50% !important;
  }
}

.cardShadow {
  box-shadow: $shadowBackground;
}

.home {
  width: 100%;
  height: 100vh;

  &-coverImage {
    width: 100%;
    height: 100vh;
    margin: 0px 0px 15px 0px;
    object-fit: cover;
  }
}

.carousel-heigth {
  height: 100%;
}

.coverImageContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  border: 3px solid rgba($rgbDarkCover, 0.8);
  border-radius: 1rem;
  background-color: rgba($rgbDarkCover, 0.15);
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.screenOverlay {
  background-size: 100% 9px !important;
  background: linear-gradient(rgba($rgbDarkCover, 0.3), rgba($rgbDarkCover, 0.3) 3px, transparent 3px, transparent 9px);
  height: 100%;
  width: 100%;
  animation: pan-overlay 22s infinite linear;
  z-index: 2;
  position: absolute;
}

@keyframes pan-overlay {
  from {
    background-position: 0% 0%;
  }

  to {
    background-position: 0% -100%;
  }
}

@keyframes pan-image {
  0% {
    background-position: 36% 42%;
    background-size: 200%;
  }

  20% {
    background-position: 30% 35%;
    background-size: 200%;
  }

  20.0001% {
    /* -- View 2 -- */
    background-position: 60% 85%;
    background-size: 500%;
  }

  40% {
    background-position: 49% 81%;
    background-size: 500%;
  }

  40.0001% {
    /* -- View 3 -- */
    background-position: 80% 42%;
    background-size: 300%;
  }

  60% {
    background-position: 84% 33%;
    background-size: 300%;
  }

  60.0001% {
    /* -- View 4 -- */
    background-position: 0% 0%;
    background-size: 300%;
  }

  80% {
    background-position: 15% 4%;
    background-size: 300%;
  }

  80.0001% {
    /* -- View 5 -- */
    background-position: 80% 10%;
    background-size: 300%;
  }

  100% {
    background-position: 72% 14%;
    background-size: 300%;
  }
}

.coverImage {
  background-image: url('../../assets/images/binaryWallpaperCover.jpg');
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  background-size: 300%;
  background-position: 0% 0%;
  // filter: sepia(100%) hue-rotate(160deg);
  opacity: 0.3;
  animation: pan-image 15s linear infinite;
}

.coverContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  // gap: 4rem;
  position: relative;
  // z-index: 3;
  // margin: 1rem;
  // padding-bottom: 6rem;
  border: 1px solid rgba($rgbDarkCover, 0.5);
  border-radius: 0.6rem;
}

.navbarText {
  font-family: 'Roboto Mono', monospace !important;
  color: white;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-size: 4.25rem;

  &-small {
    font-size: 1.25rem;
  }

  &-medium {
    font-size: 2.25rem;
  }

  &-big {
    font-size: 120px;
  }
}

.whiteBox {
  background: white;
  height: 8px;
  width: 8px;
}

.pill {
  border: 1px solid #FFF;
  border-radius: 100px;
  padding: 10px 24px;
  color: white;
}

.pillContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 34px;
  flex-wrap: wrap;
}

.pillContainerScrollable {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 34px;
  // flex-wrap: wrap;
  // width: calc(100vh - 500px);
  width: 100%;
  white-space: nowrap;
  // overflow-x: auto;
}

.sectionTitle {
  color: #101010;
  text-align: center;
  font-family: 'Tomorrow', monospace !important;
  font-size: 120px;
  font-style: normal;
  line-height: 100%;
}

@media screen and (max-width: 700px) {
  .sectionTitle {
    color: #101010;
    text-align: center;
    font-family: 'Tomorrow', monospace !important;
    font-size: 68px;
    font-style: normal;
    line-height: 100%;
  }
}

@media screen and (max-width: 425px) {
  .sectionTitle {
    color: #101010;
    text-align: center;
    font-family: 'Tomorrow', monospace !important;
    font-size: 50px;
    font-style: normal;
    line-height: 100%;
  }
}




.sectionTitleContainer {
  padding: 55px;
  display: flex;
  justify-content: center;
}

.servicesWhiteBox {
  // width: 532px;
  min-height: 312px;
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  // padding: 0 !important;
  text-align: center;
}

.ServicesContainer {
  gap: 50px !important;
}

.servicesHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 23px;
}

.nameTitle {
  width: fit-content;
  line-height: 85px;
  text-align: center;
  margin-bottom: 0px;
}


@media screen and (max-width: 550px) {
  .letter {
    font-size: 75px;
  }

  .nameTitle {
    width: fit-content;
    text-align: center;
    margin-bottom: 0px;
  }
}


.scroll-container {
  overflow: hidden;
  z-index: 5;
  width: 100%;
}

@media screen and (max-width: 460px) {
  .scroll-text {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: my-animation 15s linear infinite;
    -webkit-animation: my-animation 15s linear infinite;
    animation: my-animation 15s linear infinite;
    width: 100vw;
  }
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}